function successCallback(api, _ref) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref;
  if (status.isFinalized) {
    // Filter for success events
    const eventList = events.filter(_ref2 => {
      let {
        event
      } = _ref2;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      if (callback) callback(); // If callback not undefined
      unsub();
    }
  }
}
export async function registerProfessionalHealth(api, pair, info, callback) {
  let unsub = await api.tx.healthProfessional.register(info).signAndSend(pair, {
    nonce: -1
  }, _ref3 => {
    let {
      events,
      status
    } = _ref3;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function registerGeneticAnalystFee(api, pair, info) {
  return await api.tx.healthProfessional.register(info).paymentInfo(pair);
}
export async function updateProfessionalHealth(api, pair, info, callback) {
  let unsub = await api.tx.healthProfessional.updateInfo(info).signAndSend(pair, {
    nonce: -1
  }, _ref4 => {
    let {
      events,
      status
    } = _ref4;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function stakeProfessionalHealth(api, pair, callback) {
  let unsub = await api.tx.healthProfessional.stake().signAndSend(pair, {
    nonce: -1
  }, _ref5 => {
    let {
      events,
      status
    } = _ref5;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function createHealtProfessionalQualification(api, pair, experiences, certifications, callback) {
  let unsub = await api.tx.healthProfessionalQualification.create(experiences, certifications).signAndSend(pair, {
    nonce: -1
  }, _ref6 => {
    let {
      events,
      status
    } = _ref6;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function updateHealtProfessionalQualification(api, pair, qualificationId, experiences, certifications, callback) {
  let unsub = await api.tx.healthProfessionalQualification.update(qualificationId, experiences, certifications).signAndSend(pair, {
    nonce: -1
  }, _ref7 => {
    let {
      events,
      status
    } = _ref7;
    successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}