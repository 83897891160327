import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import errorMessages from "@/common/constants/error-messages";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
import { getSpecializationCategory, getHealthProfessionalSpecialization, getHealthProfessionalRole } from "@/common/lib/api";
import { createQualificationFee, registerGeneticAnalystFee } from "@debionetwork/polkadot-provider";
import { queryGetHealthProfessionalAccount, queryGetHealthProfessionalQualification } from "@/common/lib/polkadot-provider/query/health-professional";
import { fileTextIcon, pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import { validateForms } from "@/common/lib/validate";
import rulesHandler from "@/common/constants/rules";
import CertificationDialog from "@/common/components/Dialog/CertificateDialog";
import InsufficientDialog from "@/common/components/Dialog/InsufficientBalanceDialog";
const imageType = ["image/jpg", "image/png", "image/jpeg"];
const initialData = {
  title: "",
  issuer: "",
  month: "",
  year: "",
  description: "",
  file: {
    name: ""
  },
  supportingDocument: null /* eslint-disable camelcase */
};

export default {
  name: "GAForm",
  mixins: [validateForms],
  data: () => ({
    fileTextIcon,
    pencilIcon,
    trashIcon,
    showModalConfirm: null,
    error: false,
    isProfileLoading: false,
    showCertDialog: false,
    showUnstakeDialog: false,
    showInsufficientDialog: false,
    isLoading: false,
    info: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      phoneNumber: "",
      dateOfBirth: null,
      registerAs: null,
      profHealthCategory: null,
      anonymous: false
    },
    specialization: "",
    profileLink: "",
    profileImage: "",
    experiences: [{
      title: "",
      error: ""
    }],
    profile: {
      specifyOther: "",
      availabilityStatus: "",
      certification: [],
      qualificationId: null
    },
    document: {
      ...initialData
    },
    stakingStatus: "",
    errorProfile: "",
    categories: [],
    editId: null,
    txWeight: null,
    roles: [],
    profHealthCategories: ["Mental Health", "Physical Health"],
    isEditing: false,
    hpQualificationId: null
  }),
  components: {
    CertificationDialog,
    InsufficientDialog
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },
    onUpdate: {
      type: Function,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    submitLoading: {
      type: Boolean,
      default: false
    },
    role: {
      type: String
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet,
      walletBalance: state => state.substrate.walletBalance
    }),
    computeAvatar() {
      return this.profileImage ? this.profileImage : require("@/assets/image-placeholder.png");
    },
    disableFields() {
      if (this.isEditing) return false;
      return this.role === "health-professional" && this.isEditing;
    },
    disable() {
      if (this.role === "health-professional" && this.isEditing) {
        return false;
      }
      const {
        firstName,
        lastName,
        gender,
        email,
        phoneNumber,
        dateOfBirth,
        registerAs
      } = this.info;
      const experienceValidation = this.experiences.length === 1 && this.experiences.find(value => value.title === "");
      if (!this.profileImage || !firstName || !lastName || !gender || !dateOfBirth || !email || !phoneNumber || experienceValidation) {
        if (this.role === "genetic-analyst" || this.info.registerAs === "Medical Doctor - Specialist Practitioner") {
          if (!this.specialization) return true;
        } else {
          if (!registerAs) return true;
        }
        return true;
      }
      return false;
    },
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 1900; i--) {
        years.push(String(i));
      }
      return years;
    }
  },
  rules: {
    profile: {
      experience: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(100), rulesHandler.ENGLISH_ALPHABET],
      specialization: [rulesHandler.FIELD_REQUIRED],
      profileImage: [rulesHandler.FIELD_REQUIRED, rulesHandler.FILE_SIZE(2000000), rulesHandler.DEFAULT_IMAGE]
    },
    info: {
      firstName: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(50), rulesHandler.ENGLISH_ALPHABET],
      lastName: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(50), rulesHandler.ENGLISH_ALPHABET],
      email: [rulesHandler.FIELD_REQUIRED, rulesHandler.EMAIL, rulesHandler.MAX_CHARACTER(255), rulesHandler.ENGLISH_ALPHABET],
      phoneNumber: [rulesHandler.FIELD_REQUIRED, val => /^[0-9]+$/.test(val) || "Phone number is invalid", rulesHandler.MAX_CHARACTER(12)],
      gender: [rulesHandler.FIELD_REQUIRED],
      dateOfBirth: [rulesHandler.FIELD_REQUIRED]
    },
    profileLink: [rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(255), rulesHandler.WEBSITE],
    specialization: [rulesHandler.FIELD_REQUIRED]
  },
  async created() {
    this.isLoading = false;
    this.roles = await getHealthProfessionalRole();
    await this.getSpecialization();
    await this.getTxWeight();
    if (this.role === "health-professional") await this.fetchAccountDetail();
  },
  methods: {
    async getSpecialization() {
      if (this.role === "genetic-analyst") {
        const categories = await getSpecializationCategory();
        this.categories = categories;
        return;
      }
      const categories = await getHealthProfessionalSpecialization();
      this.categories = categories.data;
    },
    async fetchAccountDetail() {
      const data = await queryGetHealthProfessionalAccount(this.api, this.wallet.address);
      if (data) {
        this.isEditing = true;
        this.info = {
          firstName: data.info.firstName,
          lastName: data.info.lastName,
          gender: data.info.gender,
          email: data.info.email,
          phoneNumber: data.info.phoneNumber,
          dateOfBirth: data.info.dateOfBirth,
          profHealthCategory: data.info.profHealthCategory,
          anonymous: data.info.anonymous,
          myriadUsername: data.info.myriadUsername
        };
        this.specialization = data.info.role.split("|")[1];
        this.info.profHealthCategory = data.info.category;
        this.info.registerAs = data.info.role.split("|")[0];
        this.profileLink = data.info.profileLink;
        this.profileImage = data.info.profileImage;
        const dateOfBirth = String(data.info.dateOfBirth.replaceAll(",", ""));
        this.info.dateOfBirth = new Date(+dateOfBirth).toLocaleString("fr-CA", {
          day: "numeric",
          year: "numeric",
          month: "numeric"
        });
      }
      this.hpQualificationId = data.qualifications[0];
      const qualifications = await queryGetHealthProfessionalQualification(this.api, this.hpQualificationId);
      const experiences = qualifications.info.experiences;
      this.certifications = qualifications.info.certifications;
      const _experiences = [];
      experiences.map(exp => {
        const title = exp.title.split(" (")[0];
        const years = exp.title.split(" (")[1].split(" - ");
        _experiences.push({
          title,
          start: years[0],
          end: years[1].split(")")[0]
        });
      });
      this.experiences = _experiences;
    },
    async getTxWeight() {
      const qualification = {
        experience: [],
        certification: []
      };
      const getTxWeightProfile = await registerGeneticAnalystFee(this.api, this.wallet, this.profile);
      const getTxWeightQualification = await createQualificationFee(this.api, this.wallet, qualification);
      const txWeightProfile = "".concat(this.web3.utils.fromWei(String(getTxWeightProfile.partialFee), "ether"));
      const txWeightQualification = "".concat(this.web3.utils.fromWei(String(getTxWeightQualification.partialFee), "ether"));
      const txWeight = Number(txWeightProfile) + Number(txWeightQualification);
      this.txWeight = txWeight;
    },
    addExperience() {
      const experiences = this.experiences;
      if (this.role === "health-professional") {
        experiences.push({
          title: "",
          start: 0,
          end: 0
        });
      } else {
        experiences.push({
          title: ""
        });
      }
      this.experiences = experiences;
    },
    removeExperience(idx) {
      const experiences = this.experiences;
      experiences.splice(idx, 1);
      this.experiences = experiences;
    },
    onSubmitCertificate(data) {
      if (this.editId === null) {
        this.profile.certification.push({
          ...data
        });
      } else {
        this.profile.certification[this.editId] = {
          ...data
        };
      }
      this.editId = null;
      this.document = initialData;
    },
    onEditCertificate(item, index) {
      this.document = {
        ...item
      };
      this.editId = index;
      this.showCertDialog = true;
    },
    deleteCertificate(idx) {
      this.showModalConfirm = null;
      const files = this.profile.certification;
      files.splice(idx, 1);
      this.profile.certification = files;
    },
    handleSubmit() {
      var _this$isDirty;
      this.isLoading = true;
      this._touchForms("info");
      const isInfoValid = Object.values((_this$isDirty = this.isDirty) === null || _this$isDirty === void 0 ? void 0 : _this$isDirty.info).every(v => v !== null && v === false);
      const experiences = experienceValidation(this.role, this.experiences);
      const isExpError = experiences.filter(v => v.error);
      this.experiences = experiences;
      if (!isInfoValid || !this.profileImage || isExpError.length > 0) {
        this.error = true;
        return;
      }
      this.info.registerAs = this.info.registerAs === "Medical Doctor - Specialist Practitioner" ? "".concat(this.info.registerAs, "|").concat(this.specialization) : this.info.registerAs;
      const data = {
        profileImage: this.profileImage,
        experiences: this.experiences,
        profileLink: this.profileLink,
        ...this.info,
        ...this.profile
      };
      if (this.walletBalance < this.txWeight) {
        this.isLoading = false;
        this.showInsufficientDialog = true;
        return;
      }
      if (this.isEditing) {
        this.onUpdate(data, this.hpQualificationId);
        return;
      }
      this.onSubmit(data);
    },
    handleChooseFile() {
      if (this.$refs["input-file"]) this.$refs["input-file"].click();
    },
    async onAvatarChange(event) {
      if (!event.target.value) return;
      const file = event.target.files[0];
      if (!imageType.includes(file.type)) return this.errorProfile = errorMessages.FILE_FORMAT("PNG/JPG");else if (file.size > 2000000) return this.errorProfile = errorMessages.FILE_SIZE(2);
      this.isProfileLoading = true;
      const dataFile = await this.setupFileReader(file);
      const result = await uploadFile({
        title: dataFile.name,
        type: dataFile.type,
        size: dataFile.size,
        file: dataFile
      });
      const link = getFileUrl(result.IpfsHash);
      this.profileImage = link;
      this.isProfileLoading = false;
      this.errorProfile = "";
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    limitChar(value, limit) {
      return value.length > limit ? "".concat(value.substring(0, limit), "...") : value;
    }
  }
};
function experienceValidation(role, data) {
  const experiences = [];
  for (const experience of data) {
    if (rulesHandler.FIELD_REQUIRED(experience.title) !== true) {
      experiences.push({
        ...experience,
        error: rulesHandler.FIELD_REQUIRED(experience.title)
      });
    } else if (rulesHandler.ENGLISH_ALPHABET(experience.title) !== true) {
      experiences.push({
        ...experience,
        error: rulesHandler.ENGLISH_ALPHABET(experience.title)
      });
    } else if (experience.title.length > 100) {
      experiences.push({
        ...experience,
        error: errorMessages.MAX_CHARACTER(100)
      });
    } else {
      if (role === "health-professional") {
        experiences.push({
          title: "".concat(experience.title, " (").concat(experience.start, " - ").concat(experience.end, ")")
        });
      } else {
        experiences.push({
          title: experience.title
        });
      }
    }
  }
  return experiences;
}