import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import errorMessages from "@/common/constants/error-messages";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
import rulesHandler from "@/common/constants/rules";
import { validateForms } from "@/common/lib/validate";
const documentFormat = ["image/jpg", "image/png", "application/pdf", "application/msword"];
export default {
  name: "GACertificateDialog",
  mixins: [validateForms],
  data: () => ({
    document: {
      title: "",
      issuer: "",
      month: "",
      year: "",
      description: ""
    },
    file: {
      name: ""
    },
    supportingDocument: null,
    /* eslint-disable camelcase */
    uploading: false,
    errorFileMessage: "",
    selectMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  }),
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  watch: {
    data: function (newVal) {
      this.document = {
        title: newVal.title,
        issuer: newVal.issuer,
        month: newVal.month,
        year: newVal.year,
        description: newVal.description
      };
      this.file = newVal.file;
      this.supportingDocument = newVal.supportingDocument;
    }
  },
  computed: {
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 1900; i--) {
        years.push(String(i));
      }
      return years;
    },
    disabled() {
      const {
        title,
        issuer,
        month,
        year
      } = this.document;
      if (!title || !issuer || !month || !year || !this.supportingDocument) return true;
      return false;
    }
  },
  rules: {
    document: {
      title: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)],
      issuer: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)],
      month: [rulesHandler.FIELD_REQUIRED],
      year: [rulesHandler.FIELD_REQUIRED],
      description: [rulesHandler.MAX_CHARACTER(255), rulesHandler.ENGLISH_ALPHABET]
    }
  },
  methods: {
    handleSubmit() {
      var _this$isDirty;
      this._touchForms("document");
      const isDocumentValid = Object.values((_this$isDirty = this.isDirty) === null || _this$isDirty === void 0 ? void 0 : _this$isDirty.document).every(v => v !== null && v === false);
      if (!isDocumentValid) {
        if (!this.supportingDocument) this.errorFileMessage = errorMessages.REQUIRED;
        return;
      }
      const document = {
        ...this.document,
        file: this.file,
        supportingDocument: this.supportingDocument
      };
      this.onSubmit(document);
      this.handleClose();
    },
    handleClose() {
      this.document = {
        title: "",
        issuer: "",
        month: "",
        year: "",
        description: "",
        file: {
          name: ""
        },
        supportingDocument: null /* eslint-disable camelcase */
      };

      this.$emit("close");
    },
    handleChooseFile() {
      if (this.$refs["input-file"]) this.$refs["input-file"].click();
    },
    async onFileChange(event) {
      if (!event.target.value) return;
      const file = event.target.files[0];
      if (!documentFormat.includes(file.type)) return this.errorFileMessage = errorMessages.FILE_FORMAT("PNG/JPG/PDF/DOC");else if (file.size > 2000000) return this.errorFileMessage = errorMessages.FILE_SIZE(2);
      this.uploading = true;
      this.errorFileMessage = "";
      const dataFile = await this.setupFileReader(file);
      const result = await uploadFile({
        title: dataFile.name,
        type: dataFile.type,
        size: dataFile.size,
        file: dataFile
      });
      const link = getFileUrl(result.IpfsHash);
      this.supportingDocument = link;
      this.file = file;
      this.uploading = false;
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    }
  }
};