//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { alertTriangleIcon } from "@debionetwork/ui-icons";
export default {
  name: "InsufficientDialog",
  data: () => ({
    alertTriangleIcon
  }),
  props: {
    show: Boolean
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
};