//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { alertTriangleIcon } from "@debionetwork/ui-icons";
import { stakeGeneticAnalystFee, queryGeneticAnalystMinimumStakeAmount } from "@debionetwork/polkadot-provider";
import { queryProfessionalHealthMinimumStakeAmount } from "@/common/lib/polkadot-provider/query/health-professional";
export default {
  name: "StakeDialog",
  data: () => ({
    alertTriangleIcon,
    txWeight: 0,
    minimumStaking: 0,
    sufficientBalance: false
  }),
  props: {
    show: Boolean,
    isEdit: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    role: {
      type: String
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet,
      walletBalance: state => state.substrate.walletBalance
    })
  },
  async created() {
    if (this.role === "health-professional") {
      await this.getHPMinStakingAmount();
    } else {
      await this.getMinimumStakingAmount();
    }
  },
  methods: {
    async getHPMinStakingAmount() {
      try {
        const minimumStaking = await queryProfessionalHealthMinimumStakeAmount(this.api);
        const getTxWeight = await this.getTxWeight();
        this.txWeight = "".concat(this.web3.utils.fromWei(String(getTxWeight.partialFee), "ether"));
        this.minimumStaking = this.web3.utils.fromWei(String(minimumStaking.toString().split(",").join("")), "ether");
        this.sufficientBalance = this.walletBalance > Number(this.minimumStaking) + Number(this.txWeight);
      } catch (err) {
        console.error(err);
      }
    },
    async getMinimumStakingAmount() {
      try {
        const minimumStaking = await queryGeneticAnalystMinimumStakeAmount(this.api);
        const getTxWeight = await this.getTxWeight();
        this.txWeight = "".concat(this.web3.utils.fromWei(String(getTxWeight.partialFee), "ether"));
        this.minimumStaking = this.web3.utils.fromWei(String(minimumStaking.toString().split(",").join("")), "ether");
        this.sufficientBalance = this.walletBalance > Number(this.minimumStaking) + Number(this.txWeight);
      } catch (err) {
        console.error(err);
      }
    },
    async getTxWeight() {
      const txWeight = await stakeGeneticAnalystFee(this.api, this.wallet.address);
      return txWeight;
    },
    closeDialog() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit");
    }
  }
};